import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import Logo from '../../images/logo.svg';
import ScrollEffect from '../animations/components/scroll-effect';
import StyledLink from '../primitives/styled-link';
import Container from '../primitives/grid/container';
import { useHeaderContext } from './header-context';

const DesktopHeader = (): JSX.Element => {
    const { show, title, currentSlide, slides, closeGallery } = useHeaderContext();
    const { pathname } = useLocation();

    const {
        wpMenu: {
            menuItems: {
                nodes: menuItems,
            },
        },
    } = useStaticQuery(graphql`
        {
            wpMenu(name: { eq: "Primary Menu" }) {
                menuItems {
                    nodes {
                        label
                        url
                    }
                }
            }
        }
    `);

    const isGatsbyHeaderLinksInPathname = (pathname: string, headerlink: string): boolean => {
        let clonedHeaderLink = headerlink.slice(0, -1);
        if (clonedHeaderLink.endsWith('ies')) {
            clonedHeaderLink = clonedHeaderLink.slice(0, -3);
        } else if (clonedHeaderLink.endsWith('es')) {
            clonedHeaderLink = clonedHeaderLink.slice(0, -2);
        } else if (clonedHeaderLink.endsWith('s')) {
            clonedHeaderLink = clonedHeaderLink.slice(0, -1);
        } else {
            // eslint-disable-next-line no-self-assign
            clonedHeaderLink = clonedHeaderLink;
        }

        if (pathname.includes(clonedHeaderLink)) {
            return true;
        }
        return false;
    };
    return (
        <Container>
            <div css={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                transition: '0.4s',
                '[aria-hidden="true"] &': {
                    paddingLeft: 'min(calc(37.5rem - 10vw), 34vw)',
                },
            }}
            >
                <div css={{ marginRight: '30px', width: '176px' }}>
                    <ScrollEffect runOnce={false} inViewProperties={{ x: [-10, 0], opacity: [0, 1] }}>
                        <Link to="/">
                            <Logo css={{
                                width: '100%',
                                objectFit: 'cover',
                            }}
                            />
                        </Link>
                    </ScrollEffect>
                </div>
                <div
                    css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                        height: '40px',
                        borderTop: '1px solid var(--color-primary-amazon)',
                        borderBottom: '1px solid var(--color-primary-amazon)',
                    }}
                >
                    {!show && menuItems && menuItems.map(({ label, url }: any, idx) => (
                        <ScrollEffect runOnce={false} inViewProperties={{ opacity: [0, 1] }} inViewDelay={0.05 * idx}>
                            <StyledLink
                                to={url}
                                css={{
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    marginTop: '-2px',
                                    color: isGatsbyHeaderLinksInPathname(pathname, url) ? 'var(--color-primary-amazon)' : 'black',
                                    fontWeight: isGatsbyHeaderLinksInPathname(pathname, url) ? '500' : '400',
                                }}
                            >
                                {label}
                            </StyledLink>
                        </ScrollEffect>

                    ))}
                    {show && (
                        <>
                            <ScrollEffect runOnce={false} inViewProperties={{ opacity: [0, 1] }}>
                                <div
                                    css={{
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        marginTop: '-2px',
                                    }}
                                >
                                    {title}
                                </div>
                            </ScrollEffect>
                            <ScrollEffect runOnce={false} inViewProperties={{ opacity: [0, 1] }} inViewDelay={0.05}>
                                <div
                                    css={{
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        marginTop: '-2px',
                                    }}
                                >
                                    {currentSlide + 1}
                                    {' / '}
                                    {slides}
                                </div>
                            </ScrollEffect>
                            <ScrollEffect runOnce={false} inViewProperties={{ opacity: [0, 1] }} inViewDelay={0.1}>
                                <button
                                    type="button"
                                    css={{
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        marginTop: '-2px',
                                    }}
                                    onClick={() => {
                                        closeGallery();
                                    }}
                                >
                                    Close Gallery
                                </button>
                            </ScrollEffect>
                        </>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default DesktopHeader;
